<template lang="pug">
b-container#product.p-0(fluid v-if="product")
    b-alert(:show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown=0" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;")
      h6 {{msg}}

    b-container.breadcrumb-block.mb-1(fluid v-if="product")
      //- Breadcrumb trail and title.
      .p-2.ml-md-3.mr-3(align-v="center")
        //- span.breadcrumb-trail
          span.list-item-first(:class={"mobile-b-hide": !show_details}) Home 
          span(v-for="(category, index) in breadcrumb_categories" :key="index" :class="[!show_details ? `mobile-b-hide list-item-${index}` : `list-item-${index}`]")
            b-img.ml-2.mr-2(src="@/assets/images/breadcrumb-break.svg" width="12")
            b-link(@click="executeProductSearch(category.id, index)") {{ category.name_en }}
          span.active(:class="[show_details ? `mobile-b-show` : `list-item-last`]") 
            b-link(@click="show_details=!show_details") 
              b-img.ml-2.mr-2(src="@/assets/images/breadcrumb-break.svg" width="12")
              |{{ product.oem ? product.oem : 'SKU: '+ product.customer_sku || product.sku }}

        span.breadcrumb-trail
          span.list-item-first(:to="{name: 'Landing'}" :class={"mobile-b-hide": !show_details}) 
            b-link(:to="{name: 'Landing'}") {{this.$t(`global.menu.home`)}} 
          span(v-for="(category, index) in breadcrumb_categories" :key="index" :class="[!show_details ? `mobile-b-hide list-item-${index}` : `list-item-${index}`]")
            b-img.ml-2.mr-2(src="@/assets/images/breadcrumb-break.svg" width="12")
            b-link(@click="executeProductSearch(category.id, index)") 
              TranslateValue(:sourceObj="category")
          span.active.mobile-b-hide
            b-img.ml-2.mr-2(src="@/assets/images/breadcrumb-break.svg" width="12")
            | {{ $t('search.label.sku') }} :
            | {{ (product.customer_sku || product.sku) }} 

          span.active.mobile-b-show(v-if="!show_details && breadcrumb_categories && breadcrumb_categories.length")
            b-link(@click="show_details = !show_details") 
              b-img.ml-2.mr-2(src="@/assets/images/breadcrumb-break.svg" width="12")
              TranslateValue(:sourceObj="breadcrumb_categories[breadcrumb_categories.length - 1]")
  
    b-container.p-2(fluid v-if="product")    
      b-row.m-1.top-row-wrap
        b-col.left-content(cols="12" md="5" lg="4")
          .left-inner-content
            .option-heading.text-uppercase {{ $t('search.label.buying_option') }} :
            BuyingOption(v-for="(buying_option, idx) in product.oem_products" :key="idx" :isLoggedIn="isLoggedIn" :primaryBgColor="primaryBgColor" :isPart="isPart" :buyingOption="buying_option" :product="product"  :customer="customer")
            BuyingOption(v-if="product.oem_products.length == 0" :primaryBgColor="primaryBgColor" :isLoggedIn="isLoggedIn" :isPart="isPart" :buyingOption="product" :product="product" :customer="customer" :title="product.product_type_en")
          .similar-products(v-if="similar_products && similar_products.length")
            .option-header {{$t('search.label.alternative_option')}}
            .carusal-wrap
              VueSlickCarousel(v-bind="settingsImageView")      
                span.m-1(v-for="(product, idx) in similar_products" :key="idx" @click="viewProduct(product.id)")
                  b-img.rounded( v-if="product.image_url" :src="getUrl(product.image_url)" :alt="product.name_en")
                  span.sp-title {{product.sku}}

        b-col.right-content(cols="12" md="7" lg="8")  
          b-row.right-inner-content
            b-col.pl-0.image-preview-wrap(cols="12" lg="5")
              #image-preview.image-preview
                b-img( v-if="preview_image && !preview_image.includes('.mp4')" :src="preview_image")
                video.preview-product-video(v-else-if="preview_image && preview_image.includes('.mp4')" controls :src="preview_image" type="video/mp4" autoplay)
              .thumbnails(v-if="product.images && product.images.length")
                VueSlickCarousel(v-bind="settingsImageView")
                  span.m-1(v-for="(pimage, idx) in product.images" :key="idx")
                    div(v-if="pimage.image_type_priority && pimage.image_type_priority===11" @click="showImage(pimage.image_link)")
                      video.thumbnail-video(controls :src="getUrl(pimage.image_link)" type="video/mp4" muted autoplay="false" )
                    b-img.rounded( v-else-if="pimage.image_link" :src="getUrl(pimage.image_link)" @click="showImage(pimage.image_link)" :alt="pimage.image_type")

                  //- span.m-1( v-if="product.family && product.family.image_link_connector_distal" )
                  //-   b-img.rounded( :src="getUrl(product.family.image_link_connector_distal)" @click="showImage(product.family.image_link_connector_distal)")

                  //- span( v-if="product.video_link" )
                  //-   img( width="120" height="80" @click="showVideo(product.video_link)" src="/images/video_default_icon.jpg" )

                  //- span( v-if="product.family && product.family.video_link" )
                  //-   img( width="120" height="80" @click="showVideo(product.family.video_link)" src="/images/video_default_icon.jpg" )

            b-col.product-details(cols="12" lg="7")
              .product-name 
                TranslateValue(:sourceObj="product")
              .product-desc 
                TranslateValue(:sourceObj="product" objKey="description")
              .product-note 
                  TranslateValue(:sourceObj="product" objKey="note_client")
              .mob-product-img-carousel(v-if="product.images && product.images.length")
                VueSlickCarousel(v-bind="mobileSettings")
                  .mob-carousel-item(v-for="(pimage, idx) in product.images" :key="idx")
                    b-img(v-if="pimage.image_link" :src="getUrl(pimage.image_link)")
                  //- .mob-carousel-item( v-if="product.family && product.family.image_link_connector_distal" )
                  //-   b-img(:src="getUrl(product.family.image_link_connector_distal)" @click="showImage(product.family.image_link_connector_distal)")

              b-row.procuct-spec
                ProductPrice.spec-item(:productSuppliers="productSuppliers" :isLoggedIn="isLoggedIn" :isMedtenCustomer="isMedtenCustomer" :buyer_price_info="buyer_price_info" :isPart="isPart" :product="product" :customer="customer" :primaryBgColor="primaryBgColor" :primaryColor="primaryColor")
                b-col.spec-item(cols="6" md="12" xl="6")
                  .unit-group
                    span.item-label-buying-option
                      span.dot(:style="primaryBgColor")
                      |  {{ $t('search.label.unit') }} : 
                    span.unit-value.ml-2 {{getUnit(product.packaging_factor)}}

                  .unit-group.mt-2
                    span.item-label
                      span.dot(:style="primaryBgColor")
                      |  {{ $t('search.label.option_buying') }}
                    span.unit-value.ml-2 
                      TranslateValue(:sourceObj="product" objKey="product_type") 

                b-col.spec-item(cols="6" md="12" xl="6")
                  .quantity-group.mr-xl-5
                    span.item-label 
                      span.dot(:style="primaryBgColor")
                      | {{ $t('search.label.quantity') }}  :
                    b-form-input.quantity-input.ml-2(type="number" v-model="my_quantity" min="1")
                  .out-of-stock.textAlignLeft.text-xl-right.text-md-left.text-sx-right.mr-xl-5.ml-md-5.ml-xl-auto.ml-xs-auto(v-if="!product.stock_usa && !product.stock_eu && !product.stock_cn") {{$t('search.label.out_of_stock')}}
                  .out-of-stock-sub-title.textAlignLeft.text-xl-right.text-md-left.text-sx-right.ml-md-5.ml-xl-auto.ml-xs-auto(v-if="!product.stock_usa && !product.stock_eu && !product.stock_cn") ({{ $t('global.label.average_lead_time') }} 2-4 {{ $t('global.label.weeks') }})
                b-col.spec-item(cols="6" md="12" xl="6")  
                  .sku-group
                    span.item-label 
                      span.dot(:style="primaryBgColor") 
                    | {{ $t('search.label.sku') }} : 
                    span.sku.ml-2 {{ buyer_product_info.sku || product.customer_sku || product.sku }}
                b-col.spec-item(cols="12" md="12" xl="12")
                  .quantity-stock(v-if="displayStockInfoForCustomer && (product.stock_usa || product.stock_eu || product.stock_cn)") 
                    span {{ product_stock_info }}
                    span.d-block.out-of-stock-sub-title(v-if="((product.stock_usa == 0 || product.stock_usa == '' || !product.stock_usa) && (product.stock_eu > 0 || product.stock_cn > 0)) || product_stock_info == 'Out of Stock'") ({{ $t('global.label.average_lead_time') }} 10 {{ $t('global.label.days') }})


                b-col.mt-3.pr-xl-5(cols="12") 
                  b-button.text-light.add-to-cart-btn.w-100(variant="primary" @click="itemChanged" :style="primaryBgColor")
                    span {{ $t('search.label.add_to_cart') }}
   
                b-col.mt-3.pr-xl-5(cols="12") 
                  b-button.add-to-cart-btn.w-100.bg-light(@click="openProductInquiryModal" v-if="isMedtenCustomer" :style="{...primaryBorder, ...primaryColor}")
                    span {{ $t('search.label.submit_inquire') }}

                b-col.product-doc-container.mt-2.pr-xl(col="12")
                  div.d-flex.doc-main(v-if="product_documents.length > 0")
                    div Product Document : 
                    div
                      div(v-for="doc in product_documents") 
                        a.document-link(:href="getFullUrl(doc.document_link)" target="_blank") {{ doc.documentType }}

    b-container.product-specifications-details-wrap(fluid v-if="product")       
      b-row.product-specifications-details
        b-col.product-specifications(cols="12" md="4")
          .header-title
            b-img.mr-1(v-if="!isMedtenCustomer" src="@/assets/images/product/clipboard_branded.svg")
            b-img.mr-1(v-else src="@/assets/images/product/clipboard.svg")
            | {{ $t('search.label.specifications') }} 
            //- {{show_more_index}} - {{show_more_char_count}}

          b-row.group-item(v-for="(specification, key) in filterSpecifications" :key="key")
            b-col(cols="4" md="4")
              span.dot(:style="primaryBgColor")
              span {{specification.name}} :
            b-col(cols="8" md="8")
              span {{specification.value}}
          b-row.group-item(v-if="productSets && productSets.length")
            b-col(cols="4" md="4")
              span.dot(:style="primaryBgColor")
              span Components:
            b-col(cols="8" md="8")
              div(v-for="(productSet, key) in productSets" :key="key") {{ productSet.quantity }} x {{productSet.description}} ({{ productSet.child_sku }})
          .show-more(v-if="(specifications.length > 5) || (getArrayCount(specifications, 'name', 'value') > 150)" :class="{'expanded': !isShowMore('specification') }") 
            a(@click="showMore('specification')") {{isShowMore('specification') ? $t('search.label.show_all') : $t('search.label.show_less')}}

        b-col.product-reference(cols="12" md="4")
          .header-title
            b-img.mr-1(v-if="!isMedtenCustomer" src="@/assets/images/product/reference_branded.svg")
            b-img.mr-1(v-else src="@/assets/images/product/reference.svg")
            | {{ $t('search.label.part_number_ref') }} 
          .search-reference 
            b-form-input.input-item( type="text" v-model="search_oem" @input="onInput($event, 'oem')" :placeholder=`$t('search.label.search_confirm_ref')`)
            b-img(src="@/assets/images/product/search.svg")
            span.input-match.mid-screen-hide(v-if='search_oem != "" && match_count_oem != 0') {{match_count_oem}} {{ $t('product.cart.match') }} 
          b-row.group-item(v-for="(oem, key) in match_oem_references" :key="key+'oem-match'")
            b-col(cols="6" md="6")
              span.dot(:style="primaryBgColor")
              span
                TranslateValue(:sourceObj="oem" objKey="brand")
            b-col(cols="6" md="6")
              span(v-html="removeMarkTags(oem.name)")
          .match-divider.border-top.mt-2.mb-2.ml-2(v-if="match_oem_references && match_oem_references.length") 
            span Full OEM Reference List
          b-row.group-item(v-for="(oem, key) in filterOemReferences" :key="key")
            b-col(cols="6" md="6")
              span.dot(:style="primaryBgColor")
              span
                TranslateValue(:sourceObj="oem" objKey="brand")
            b-col(cols="6" md="6")
              span(v-html="oem.name") 
          .show-more(v-if="(this.oem_references && this.oem_references.length > 5) || (this.getArrayCount(this.oem_references, 'brand_en', 'name') > 150)" :class="{'expanded': !isShowMore('specification') }") 
            a(@click="showMore('oem')")  {{isShowMore('oem') ? $t('search.label.show_all') : $t('search.label.show_less')}}

        b-col.product-compatiblity(cols="12" md="4")
          .header-title
            b-img.mr-1(v-if="!isMedtenCustomer" src="@/assets/images/product/compatiblity_branded.svg")
            b-img.mr-1(v-else src="@/assets/images/product/compatiblity.svg")
            | {{ $t('search.label.compatibility') }} 
          .search-compatiblity 
            b-form-input.input-item(type="text" v-model="search_compatibility" @input="onInput($event, 'compatiblity')" :placeholder=`$t('search.label.search_confirm_com')`)
            b-img(src="@/assets/images/product/search.svg")
            span.input-match.mid-screen-hide(v-if='search_compatibility!="" && match_count_compatibility != 0') {{match_count_compatibility}} {{ $t('product.cart.match') }} 
          b-row.group-item(v-for="(item, key) in match_compatibility" :key="key+'com-match'")
            b-col(cols="6" md="6")
              span.dot(:style="primaryBgColor") 
              span
                TranslateValue(:sourceObj="item" objKey="brand")
            b-col(cols="6" md="6")
              span(v-html="item.model")
          .match-divider.border-top.mt-2.mb-2.ml-2(v-if="match_compatibility && match_compatibility.length") 
            span Full Compatiblity List
          b-row.group-item(v-for="(item, key) in filterCmpatibility" :key="key")
            b-col(cols="6" md="6")
              span.dot(:style="primaryBgColor")
              span
                TranslateValue(:sourceObj="item" objKey="brand")
            b-col(cols="6" md="6")
              span(v-html="item.model")
          .show-more(v-if="(this.compatibility && this.compatibility.length > 5) || (this.getArrayCount(this.compatibility, 'brand_en', 'model') > 150)" :class="{'expanded': !isShowMore('specification') }") 
            a(@click="showMore('compatiblity')") {{isShowMore('compatiblity') ? $t('search.label.show_all') : $t(`search.label.show_less`)}}

    b-container.mt-5.mb-2.product-features-block(fluid v-if="use_with && use_with.length")
      p.text-center.h3.mb-4 {{$t('product.label.use_with')}}
      VueSlickCarousel.desktop-show(v-bind="settings")
        template(#prevArrow="arrowOption")
          b-img.custom-arrow(src="@/assets/images/btn-left.svg")
        GlobalCard(v-for="(item, key) in use_with" :key="key" :item="item" page="cav")
        template(#nextArrow="arrowOption")
          b-img.custom-arrow(src="@/assets/images/btn-right.svg")
      b-row.mobile-show
        b-col.p-0.pb-3(cols="6" md="3" v-for="item in use_with" :key="'mob-'+item.id")
          GlobalCard(:item="item" page="cav")
      .load-more-icon.justify-content-center.mobile-show(v-if="display_cav_index < use_with.length") 
        b-img.custom-arrow(src="@/assets/images/btn-load.svg" @click="loadmoreCav()")

    b-container.mt-5.mb-4.product-features-block(fluid v-if="customer_views && customer_views.length")
      p.text-center.h3.mb-4 {{$t('product.label.customers_viewed')}}
      VueSlickCarousel.desktop-show(v-bind="settings")
        template(#prevArrow="arrowOption")
          b-img.custom-arrow(src="@/assets/images/btn-left.svg")
        GlobalCard(v-for="(item, key) in customer_views" :key="key" :item="item" page="cav")
        template(#nextArrow="arrowOption")
          b-img.custom-arrow(src="@/assets/images/btn-right.svg")
      b-row.mobile-show
        b-col.p-0.pb-3(cols="6" md="3" v-for="item in customer_views" :key="'mob-'+item.id")
          GlobalCard(:item="item" page="cav")
      .load-more-icon.justify-content-center.mobile-show(v-if="display_cav_index < customer_views.length") 
        b-img.custom-arrow(src="@/assets/images/btn-load.svg" @click="loadmoreCav()")

    MissionPromiseStatement
    Footer
    ProductInquiryModal(:product="productBackup")
</template>

<script>
import { mapActions, mapState, mapGetters } from 'pinia';
import { useApiUtilStore } from '../stores/apiutil-st';
import { useAuthStore } from '../stores/auth-st';
import { useCartStore } from '../stores/cart-st';
import { useDbStore } from '../stores/db-st';
import { useBaseStore } from '@/stores/base-st';
import debounce from 'lodash.debounce';
import { useProductSearchStore } from '@/stores/product-search-st';
import GlobalCard from '@/components/GlobalCard.vue';
import TranslateValue from '@/components/TranslateValue.vue';
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
import ProductInquiryModal from '@/components/ProductInquiryModal.vue';
import ProductPrice from '@/components/ProductPrice.vue';
import BuyingOption from '@/components/BuyingOption.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import utils from '@/mixins/utils';
import productaddons from '@/mixins/product-addons';
import { VueOfflineStorage } from 'vue-offline';
export default {
  name: 'Product',
  components: {
    TranslateValue,
    VueSlickCarousel,
    GlobalCard,
    Footer,
    MissionPromiseStatement,
    BuyingOption,
    ProductInquiryModal,
    ProductPrice,
  },
  mixins: [utils, productaddons],
  props: {
    product_id: {
      required: true,
    },
  },

  data() {
    return {
      productSuppliers: [],
      productSets: [],
      buyer_price_info: null,
      showListPrice: false,
      buyer_product_info: {},
      msg: '',
      product: null,
      productBackup: null,
      preview_image: null,
      preview_video: null,
      my_quantity: 1,
      show_details: false,
      dismissCountDown: 0,
      product_certificates: [],
      product_documents: [],
      //- family_products:[],
      display_cav_index: 6,
      match_count_oem: 0,
      match_count_compatibility: 0,
      search_oem: '',
      search_compatibility: '',

      specifications: [],
      compatibility: [],
      oem_references: [],
      image_url: '',

      match_compatibility: [],
      match_oem_references: [],

      mobileSettings: {
        dots: true,
        arrows: false,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },

      settingsImageView: {
        dots: false,
        arrows: true,
        infinite: false,
        // nextArrow: '<button class="carousel-arrow next inverted"></button>',
        // prevArrow: '<button class="carousel-arrow prev inverted"></button>',
        slidesToShow: 4,
        slidesToScroll: 4,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
        variableWidth: true,
        //- responsive: [
        //-   {
        //-     breakpoint: 1200,
        //-     settings: {
        //-       slidesToShow: 4,
        //-       slidesToScroll: 4,
        //-       infinite: false,
        //-       dots: false,
        //-       arrows: true,
        //-       nextArrow: '<button class="carousel-arrow next inverted"></button>',
        //-       prevArrow: '<button class="carousel-arrow prev inverted"></button>',
        //-     }
        //-   },
        //-   {
        //-     breakpoint: 1024,
        //-     settings: {
        //-       slidesToShow: 3,
        //-       slidesToScroll: 3,
        //-       infinite: false,
        //-       dots: false,
        //-       arrows: true,
        //-     }
        //-   },
        //-   {
        //-     breakpoint: 678,
        //-     settings: {
        //-       slidesToShow: 2,
        //-       slidesToScroll: 2,
        //-       infinite: false,
        //-       dots: false,
        //-       arrows: true,
        //-     }
        //-   }
        //- ]
      },

      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        nextArrow: '<button class="carousel-arrow next inverted"></button>',
        prevArrow: '<button class="carousel-arrow prev inverted"></button>',
        //- centerMode: true,
        //- centerPadding: '10px',
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: false,
              dots: false,
              arrows: true,
              nextArrow:
                '<button class="carousel-arrow next inverted"></button>',
              prevArrow:
                '<button class="carousel-arrow prev inverted"></button>',
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: false,
              dots: false,
              arrows: true,
              nextArrow:
                '<button class="carousel-arrow next inverted"></button>',
              prevArrow:
                '<button class="carousel-arrow prev inverted"></button>',
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
              dots: false,
              arrows: true,
            },
          },
          {
            breakpoint: 678,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false,
              dots: false,
              arrows: true,
            },
          },
        ],
      },
      show_all: false,
      show_more_index: 5,
      show_more_char_count: 150,
      customer_views: [],
      similar_products: [],
      use_with: [],
    };
  },
  mounted() {
    window.addEventListener('resize', this.setImagePreviewHeight);
  },
  unmounted() {
    window.removeEventListener('resize', this.setImagePreviewHeight);
  },
  computed: {
    ...mapState(useApiUtilStore, ['busy']),
    ...mapState(useAuthStore, [
      'isLoggedIn',
      'is_buyer',
      'org_principal',
      'user_principal',
    ]),
    ...mapState(useCartStore, ['cart_items']),
    ...mapState(useDbStore, [
      'categories',
      'customer',
      'isMedtenCustomer',
      'lifecycles',
      'documentTypes',
    ]),
    ...mapState(useProductSearchStore, [
      'product_search_results',
      'product_search_term',
      'product_search_total',
      'search_criteria_provided',
    ]),
    ...mapState(useBaseStore, ['message']),
    ...mapGetters(useDbStore, [
      'isMedtenCustomer',
      'primaryColor',
      'secondaryColor',
      'primaryBgColor',
      'secondaryBgColor',
      'primaryBgColorWithoutBorder',
      'secondaryBgColorWithoutBorder',
      'primaryBorder',
    ]),
    breadcrumb_categories() {
      if (
        !this.product ||
        !this.product.category_id ||
        this.categories.length === 0
      )
        return [];
      let ancestors = [];
      let depth = 0;
      let category = this.categories.find(
        (c) => c.id == this.product.category_id
      );
      while (depth < 5 && category) {
        // 5 is a reasonable depth limit
        ancestors.push(category);
        //find parent category and add it to result
        category = this.categories.find((c) => c.id == category.parent_id);
        depth++;
      }

      return ancestors.reverse();
    },
    shouldShowListPrice() {
      return this.getShouldShowListPrice(
        this.isPart,
        this.isLoggedIn,
        this.showListPrice
      );
    },
    compatibility_items() {
      if (!this.product || !this.product.compatibility) return [];
      let summary = [];
      this.product.compatibility.forEach((pec) => {
        let current = summary.find((x) => x.brand == pec.brand_en);
        if (!current) {
          summary.push({ brand: pec.brand_en, models: pec.model });
        } else {
          current.models += `, ${pec.model}`;
        }
      });
      return summary;
    },
    derived_oem() {
      return this.product.product_type_id == 1
        ? this.product.oem_brand_en + ' ' + this.product.oem
        : this.product.product_type_id == 4
        ? this.product.oem_brand_en + ' ' + this.product.oem + ' OEM as is'
        : 'Replaces: ' + this.product.oem_brand_en + ' ' + this.product.oem;
    },

    isAccessory() {
      return (
        this.breadcrumb_categories.length > 0 &&
        this.breadcrumb_categories[0].id == 1
      );
    },

    isPart() {
      return (
        this.breadcrumb_categories.length > 0 &&
        this.breadcrumb_categories[0].id == 2
      );
    },

    isRepairService() {
      return (
        this.breadcrumb_categories.length > 0 &&
        this.breadcrumb_categories[0].id == 3
      );
    },

    getCertificates() {
      let certificates = this.product.certificates.map((c) => c.name_en);
      return certificates.join(', ');
    },

    filterCmpatibility() {
      if (
        this.getArrayCount(this.compatibility, 'brand_en', 'model') > 150 &&
        !this.show_all
      ) {
        let filter_data = this.getArrayByCharCount(
          this.compatibility,
          'brand_en',
          'model',
          this.show_more_char_count
        );
        if (filter_data.length <= this.show_more_index) {
          return filter_data;
        }
      }

      if (
        this.compatibility &&
        this.compatibility.length > this.show_more_index
      ) {
        return this.compatibility.slice(0, this.show_more_index);
      }
      return this.compatibility;
    },

    filterOemReferences() {
      if (
        this.getArrayCount(this.oem_references, 'brand_en', 'name') > 150 &&
        !this.show_all
      ) {
        let filter_data = this.getArrayByCharCount(
          this.oem_references,
          'brand_en',
          'name',
          this.show_more_char_count
        );
        if (filter_data.length <= this.show_more_index) {
          return this.fillMissing(filter_data);
        }
      }

      if (
        this.oem_references &&
        this.oem_references.length > this.show_more_index
      ) {
        return this.fillMissing(
          this.oem_references.slice(0, this.show_more_index)
        );
      }
      return this.fillMissing(this.oem_references);
    },

    filterSpecifications() {
      if (
        this.getArrayCount(this.specifications, 'name', 'value') > 150 &&
        !this.show_all
      ) {
        let filter_data = this.getArrayByCharCount(
          this.specifications,
          'name',
          'value',
          this.show_more_char_count
        );
        if (filter_data.length <= this.show_more_index) {
          return filter_data;
        }
      }
      if (
        this.specifications &&
        this.specifications.length > this.show_more_index
      ) {
        return this.specifications.slice(0, this.show_more_index);
      }
      return this.specifications;
    },

    filterFamilyProducts() {
      if (
        this.customer_views &&
        this.customer_views.length > this.display_cav_index
      ) {
        return this.customer_views.slice(0, this.display_cav_index);
      }
      return this.customer_views;
    },
    listPriceClass() {
      if (this.buyer_price_info) {
        return 'strikethrough';
      }
      return '';
    },
    product_stock_info() {
      return this.getProductStockInfo(this.product);
    },
    showYourPrice() {
      if (this.isLoggedIn) {
        return true;
      }
      return false;
    },
    yourPrice() {
      console.log("isPart", this.isPart);
       if(this.buyer_price_info && this.buyer_price_info.priorityPrices && this.buyer_price_info.priorityPrices.length>0){
        const priorityPrice= this.buyer_price_info?.priorityPrices.find((item)=>item.marketing_region_id===this.customer.marketing_region_id);
        return priorityPrice.bulk_price;
       }
      else if (
        (this.isPart || !this.isPart) &&
        (!this.product.outsourced||this.product.outsourced )&&
        this.buyer_price_info &&
        this.buyer_price_info?.discount_price
      ) {
        console.log(this.buyer_price_info?.discount_price);
        return this.buyer_price_info?.discount_price;
      } else if (
        (this.isPart || !this.isPart) &&
        !this.product.outsourced &&
        this.buyer_price_info &&
        this.buyer_price_info?.discount_percentage
      ) {
        console.log(this.buyer_price_info?.discount_percentage);
        if (this.customer.marketing_region_id == "1") {
          return this.buyer_price_info?.price.discounted_factory_price_us;
        } else if (this.customer.marketing_region_id == "2") {
          return this.buyer_price_info?.price.discounted_factory_price_eu;
        } else if (this.customer.marketing_region_id == "3") {
          return this.buyer_price_info?.price.discounted_factory_price_cn;
        }
      } else if (!this.product.outsourced) {
        if (this.customer.marketing_region_id == "1") {
          return this.buyer_price_info?.price.factory_price_us;
        } else if (this.customer.marketing_region_id == "2") {
          return this.buyer_price_info?.price.factory_price_eu;
        } else if (this.customer.marketing_region_id == "3") {
          return this.buyer_price_info?.price.factory_price_cn;
        }
      } else if (this.product.outsourced) {
        if (this.customer.marketing_region_id == "1") {
          return this.buyer_price_info?.price.dealer_price_us;
        } else if (this.customer.marketing_region_id == "2") {
          return this.buyer_price_info?.price.dealer_price_eu;
        } else if (this.customer.marketing_region_id == "3") {
          return this.buyer_price_info?.price.dealer_price_cn;
        }
      }

      //  else if(!this.product.outsourced && (this.isPart||this.buyer_price_info?.parentCatId=="2")){
      //          if(this.customer.marketing_region_id=='1'){
      //         return this.buyer_price_info?.price.dealer_price_us
      //         }else if (this.customer.marketing_region_id=='2'){
      //         return this.buyer_price_info?.price.dealer_price_eu
      //       }else if (this.customer.marketing_region_id=='3'){
      //       return this.buyer_price_info?.price.dealer_price_cn
      //       }

      //     }
    },
  },

  async created() {
    this.loadProductCertificates();
    this.loadProductDocuments();
    await this.loadProduct();
    await this.loadProductSuppliers();
    //- if(this.product && this.cart_items){
    //-   let match = this.cart_items.find(ci=>ci.id===this.product.id);
    //-   if(match){
    //-     this.my_quantity = match.quantity;
    //-   }
    //- }
  },

  watch: {
    $route(to, from) {
      if (to && from && to.path !== from.path) {
        this.loadProduct();
      }
    },
  },

  methods: {
    ...mapActions(useCartStore, [
      'createCartItem',
      'findCartItem',
      'removeCartItem',
      'updateCartItem',
    ]),
    ...mapActions(useProductSearchStore, ['searchProducts']),
    removeMarkTags(htmlString) {
      return htmlString.replace(/<\/?mark>/g, '');
    },
    fillMissing(data) {
      return data.map((item) => {
        return {
          ...item,
          brand_zh: item.brand_zh || item.brand_en,
        };
      });
    },
    findLifecycle(lifeCycle) {
      let requiredLifecycle = this.lifecycles.find((item) => {
        if (item.name_en === lifeCycle) {
          return true;
        }
      });
      return requiredLifecycle;
    },
    showAlert() {
      this.dismissCountDown = 3;
    },
    getUrl(url) {
      if (url) return this.getFullUrl(url);
      return require(`@/assets/images/Default_Missing_Image.png`);
    },
    isParts(id) {
      let category = this.categories.find((c) => c.id == id);
      let parent_id = id;
      if (category) {
        parent_id = category['parent_id'];
        while (parent_id != 1 && parent_id != 2 && category) {
          category = this.categories.find((c) => c.id == parent_id);
          if (category) {
            parent_id = category['parent_id'];
          }
        }
      }
      if (parent_id == 2) {
        return true;
      } else {
        return false;
      }
    },
    viewProduct(product_id) {
      //- console.log(product_id);
      this.$router.push({
        name: 'Product',
        params: { product_id: product_id },
      });
    },
    getArrayByCharCount(list, key, valkey, maxChar) {
      let filter_data = [];
      let count = 0;
      let temp = JSON.parse(JSON.stringify(list));
      temp.forEach((item) => {
        if (item[key] && count != maxChar) {
          let templength = count + item[key].length + item[valkey].length;
          if (templength <= maxChar) {
            count = templength;
            filter_data.push(item);
          } else {
            let limit = maxChar - count;
            item[valkey] = item[valkey].substring(0, limit) + '...';
            count = maxChar;
            if (limit > item[key].length) {
              filter_data.push(item);
            }
          }
        }
      });
      return filter_data;
    },

    getArrayCount(list, key, valkey) {
      let count = 0;
      let temp = JSON.parse(JSON.stringify(list));
      temp.forEach((item) => {
        if (item[key] && item[valkey]) {
          count = count + item[key].length + item[valkey].length;
        }
      });
      return count;
    },

    isShowMore(type) {
      if (type == 'specification') {
        if (
          this.specifications.length > this.show_more_index ||
          this.getArrayCount(this.specifications, 'name', 'value') >
            this.getArrayCount(this.filterSpecifications, 'name', 'value')
        )
          return true;
        return false;
      } else if (type == 'oem') {
        if (
          (this.oem_references &&
            this.oem_references.length > this.show_more_index) ||
          this.getArrayCount(this.oem_references, 'brand_en', 'name') >
            this.getArrayCount(this.filterOemReferences, 'brand_en', 'name')
        ) {
          return true;
        }
        return false;
      } else if (type == 'compatiblity') {
        if (
          this.compatibility.length > this.show_more_index ||
          this.getArrayCount(this.compatibility, 'brand_en', 'model') >
            this.getArrayCount(this.filterCmpatibility, 'brand_en', 'model')
        ) {
          return true;
        }
        return false;
      }
    },

    showMore(type) {
      if (this.show_all) {
        this.show_all = false;
        this.show_more_index = 5;
        this.show_more_char_count = 150;
      } else {
        if (this.specifications.length > this.show_more_index)
          this.show_more_index = this.specifications.length;
        if (
          this.getArrayCount(this.specifications, 'name', 'value') >
          this.show_more_char_count
        )
          this.show_more_char_count = this.getArrayCount(
            this.specifications,
            'name',
            'value'
          );

        if (this.oem_references.length > this.show_more_index)
          this.show_more_index = this.oem_references.length;
        if (
          this.getArrayCount(this.oem_references, 'brand_en', 'name') >
          this.show_more_char_count
        )
          this.show_more_char_count = this.getArrayCount(
            this.oem_references,
            'brand_en',
            'name'
          );

        if (this.compatibility.length > this.show_more_index)
          this.show_more_index = this.compatibility.length;
        if (
          this.getArrayCount(this.compatibility, 'brand_en', 'model') >
          this.show_more_char_count
        )
          this.show_more_char_count = this.getArrayCount(
            this.compatibility,
            'brand_en',
            'model'
          );
        this.show_all = true;
      }
      //- switch(type){
      //-   case 'specification':
      //-     if(this.specifications.length > this.show_more_index || (this.getArrayCount(this.specifications, 'name', 'value') > this.show_more_char_count && this.show_more_index == 5)){
      //-       if(this.specifications.length > this.show_more_index) this.show_more_index = this.specifications.length;
      //-       if(this.getArrayCount(this.specifications, 'name', 'value') > this.show_more_char_count) this.show_more_char_count = this.getArrayCount(this.specifications, 'name', 'value');
      //-     }else{
      //-       this.show_more_index = 5;
      //-       this.show_more_char_count = 150;
      //-     }
      //-     break;
      //-   case 'oem':
      //-     if(this.oem_references && this.oem_references.length > this.show_more_index || (this.getArrayCount(this.oem_references, 'brand_en', 'name') > this.show_more_char_count && this.show_more_index == 5)){
      //-       if(this.oem_references.length > this.show_more_index) this.show_more_index = this.oem_references.length;
      //-       if(this.getArrayCount(this.oem_references, 'brand_en', 'name') > this.show_more_char_count) this.show_more_char_count = this.getArrayCount(this.oem_references, 'brand_en', 'name');
      //-     }else{
      //-       this.show_more_index = 5;
      //-       this.show_more_char_count = 150;
      //-     }
      //-     break;
      //-   case 'compatiblity':
      //-     if(this.compatibility && this.compatibility.length > this.show_more_index || (this.getArrayCount(this.compatibility, 'brand_en', 'model') > this.show_more_char_count && this.show_more_index == 5)){
      //-       if(this.compatibility.length > this.show_more_index) this.show_more_index = this.compatibility.length;
      //-       if(this.getArrayCount(this.compatibility, 'brand_en', 'model') > this.show_more_char_count) this.show_more_char_count = this.getArrayCount(this.compatibility, 'brand_en', 'model');
      //-     }else{
      //-       this.show_more_index = 5;
      //-       this.show_more_char_count = 150;
      //-     }
      //-     break;
      //-   default:
      //-     console.log('case not found')
      //- }
    },

    onInput: debounce(function (val, type) {
      //- console.log("*****", val, "*****", type);
      this.search(val, type);
    }, 700),

    // Inside the search method of the main code
    search(term, type) {
      switch (type) {
        case 'oem':
          this.match_count_oem = 0;
          if (this.oem_references && this.oem_references.length) {
            this.match_oem_references = [];
            this.loadOemReferences(this.productBackup);
            this.oem_references.forEach((item) => {
              if (item.name) {
                let index = item.name.toLowerCase().indexOf(term.toLowerCase());
                let indexKey = item.brand_en
                  .toLowerCase()
                  .indexOf(term.toLowerCase());
                if ((index != -1 || indexKey != -1) && term) {
                  let count = (item.name.match(new RegExp(term, 'gi')) || [])
                    .length;
                  // Highlight the match in yellow using <mark> tags
                  item.name = item.name.replace(
                    new RegExp(term, 'gi'),
                    (match) =>
                      `<mark style="background-color: yellow;">${match}</mark>`
                  );
                  this.match_count_oem =
                    Number(this.match_count_oem) + Number(count);
                  if (indexKey != -1) {
                    let countBrand = (
                      item.brand_en.match(new RegExp(term, 'gi')) || []
                    ).length;
                    // Highlight the match in yellow using <mark> tags
                    item.brand_en = item.brand_en.replace(
                      new RegExp(term, 'gi'),
                      (match) =>
                        `<mark style="background-color: yellow;">${match}</mark>`
                    );
                    this.match_count_oem =
                      Number(this.match_count_oem) + Number(countBrand);
                  } else {
                    item.brand_en = item.brand_en;
                  }
                  this.match_oem_references.push(item);
                }
              }
            });

            this.show_more_index = this.oem_references.length;
          }
          break;
        case 'compatiblity':
          this.match_count_compatibility = 0;
          if (this.compatibility && this.compatibility.length) {
            this.match_compatibility = [];
            this.loadCompatibility(this.productBackup);
            this.compatibility.forEach((item) => {
              if (item.model) {
                let index = item.model
                  .toLowerCase()
                  .indexOf(term.toLowerCase());
                let indexKey = item.brand_en
                  .toLowerCase()
                  .indexOf(term.toLowerCase());
                if ((index != -1 || indexKey != -1) && term) {
                  let count = (
                    item.model.match(new RegExp(term.toLowerCase(), 'gi')) || []
                  ).length;
                  // Highlight the match in yellow using <mark> tags
                  item.model = item.model.replace(
                    new RegExp(term, 'gi'),
                    (match) =>
                      `<mark style="background-color: yellow;">${match}</mark>`
                  );
                  this.match_count_compatibility =
                    this.match_count_compatibility + count;
                  if (indexKey != -1) {
                    let countBrand = (
                      item.brand_en.match(new RegExp(term, 'gi')) || []
                    ).length;
                    // Highlight the match in yellow using <mark> tags
                    item.brand_en = item.brand_en.replace(
                      new RegExp(term, 'gi'),
                      (match) =>
                        `<mark style="background-color: yellow;">${match}</mark>`
                    );
                    this.match_count_compatibility =
                      Number(this.match_count_compatibility) +
                      Number(countBrand);
                  } else {
                    item.brand_en = item.brand_en;
                  }
                  this.match_compatibility.push(item);
                }
              }
            });

            this.show_more_index = this.product.compatibility.length;
          }
          break;
        default:
          console.log('case not found');
      }
    },

    loadmoreCav() {
      if (
        this.customer_views &&
        this.customer_views.length > this.display_cav_index
      ) {
        this.display_cav_index = this.customer_views.length;
      }
    },

    openProductInquiryModal() {
      this.$bvModal.show('product-inquiry-modal');
    },
    clearSearch() {
      VueOfflineStorage.set('refine_filter_options', null);
      VueOfflineStorage.set('refine_models', null);
      VueOfflineStorage.set('refine_brands', null);
      VueOfflineStorage.set('refine_categories', null);
      VueOfflineStorage.set('refine_lifecycles', null);
    },

    async executeProductSearch(id, index) {
      this.clearSearch();
      let category_id = id;
      this.search_term = '';
      let parms = {};
      // debugger
      // below written condition disables the onclick function on accessories of breadcrum
      if (category_id) {
        if (index == 0) {
          let ids = [
            ...this.categories
              .filter((c) => c.parent_id == category_id)
              .map((c) => c.id),
          ];
          parms.category_ids = [
            category_id,
            ...this.categories
              .filter((c) => ids.indexOf(c.parent_id) != -1)
              .map((c) => c.id),
          ];
        } else {
          parms.category_ids = [
            category_id,
            ...this.categories
              .filter((c) => c.parent_id == category_id)
              .map((c) => c.id),
          ];
        }
      }
      //- console.log('category_ids', parms.category_ids);
      //- return;
      //- useProductSearchStore().$reset(); // when executed from here, always starts a totally fresh search.
      await this.searchProducts(parms);
      //Trigger product selection when only one result.
      if (
        this.product_search_total === 1 &&
        this.product_search_results[0].id
      ) {
        //- if(this.$route.name !== 'Product') this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        if (this.$route.name !== 'Product') {
          this.$router.push({
            name: 'Product',
            params: { product_id: this.product_search_results[0].id },
          });
        } else if (
          this.$route.params &&
          this.$route.params['product_id'] &&
          this.$route.params['product_id'] != this.product_search_results[0].id
        ) {
          this.$router.push({
            name: 'Product',
            params: { product_id: this.product_search_results[0].id },
          });
        }
      } else if (this.product_search_total > 1) {
        if (this.$route.name !== 'Search') {
          this.$router.push({
            name: 'Search',
            params: { search_term: this.search_term },
          });
        } else {
          if (this.product_search_total === 0) {
            useBaseStore().$patch({
              message: this.$t(`global.verbiage.product_not_found`),
            });
          }
        }
      }
    },

    getBuyingOptionTitle(option) {
      if (option.product_type_id < 3) return 'New ' + option.product_type_en;
      else return option.product_type_en;
    },

    getBuyingUnit(packaging_factor) {
      if (!packaging_factor || packaging_factor == 1) return 'ea';
      else if (
        packaging_factor == 'set' ||
        packaging_factor == 'Set' ||
        packaging_factor == 'Kit' ||
        packaging_factor == 'kit'
      )
        return packaging_factor;
      else return 'box of ' + packaging_factor;
    },

    getUnit(packaging_factor) {
      if (packaging_factor == 1) return packaging_factor;
      else if (
        packaging_factor == 'set' ||
        packaging_factor == 'Set' ||
        packaging_factor == 'Kit' ||
        packaging_factor == 'kit'
      )
        return packaging_factor;
      else if (!packaging_factor) return 1;
      return 'Box of ' + packaging_factor;
    },
    async loadProductSets() {
      let api = useApiUtilStore();
      const r = await api.callApi({
        method: 'GET',
        url: `/products/${this.product_id}/sets`,
        jwt: VueOfflineStorage.get('mtcustomer'),
      });
      if (r.success) {
        this.productSets = r.data.product_set_views;
      }
    },
    async loadProductSuppliers() {
      let api = useApiUtilStore();
      const r = await api.callApi({
        method: 'GET',
        url: `/products/${this.product_id}/suppliers`,
        jwt: VueOfflineStorage.get('mtcustomer'),
      });
      if (r.success) {
        this.productSuppliers = r.data.product_suppliers;
      }
    },
    async loadProduct() {
      this.similar_products = [];
      this.customer_views = [];
      let api = useApiUtilStore();
      let r = await api.callApi({
        method: 'GET',
        url: `/products/${this.product_id}/detail`,
        jwt: VueOfflineStorage.get('mtcustomer'),
      });
      if (r.success) {
        this.loadProductSets();
        this.product = r.data;
        if (!this.product.oem) {
          this.product.oem_products = this.product.oem_products.filter(
            (item) => item.id == this.product.id
          );
        }
        this.productBackup = r.data;
        await this.loadBuyerPrice();
        this.loadSpecification(r.data);
        this.loadCompatibility(r.data);
        await this.loadOemReferences(r.data);
        await this.loadBuyerProduct();
        this.adjustOemReferencesForExtendedSkus();
        this.loadProductInSametFamily();

        //default the selected image.
        this.image_url = '';
        if (this.product.family) {
          let family_img = {
            id: 0,
            product_id: this.product.id,
            image_type_id: this.product.family.image_type_id,
            image_type: '',
            priority_order: 0,
            image_type_priority: this.product.family.image_type_priority
              ? this.product.family.image_type_priority
              : 5,
            image_link: this.product.family.image_link_connector_distal,
            created: '',
            updated: '',
            version: 0,
          };
          if (
            this.product &&
            this.product.images &&
            this.product.images.length
          ) {
            this.product.images.push(family_img);
          } else {
            this.product.images = [family_img];
          }
        }
        if (this.product && this.product.images && this.product.images.length) {
          //- let imgs = this.product.images.filter(item => { return item.image_type=='OEM' || item.image_type_id == 1}); // OEM
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (item.image_type.trim()=='Full Product'  || item.image_type_id == 2)})]; // Full Product
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (item.image_type.trim()=='Full Product - PS' || item.image_type_id == 9)})]; // Full Product - PS
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (item.image_type.trim()=='Distal Connector' || item.image_type_id == 8)})]; // Distal Connector
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (item.image_type.trim()=='Proximal Connector' || item.image_type_id == 7)})];
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (item.image_type.trim()=='Detail' || item.image_type_id == 3)})];
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (item.image_type.trim()=='Package' || item.image_type_id == 4)})];
          //- imgs = [...imgs, ...this.product.images.filter(item => { return (
          //-   item.image_type_id != 4
          //-   && item.image_type.trim()!='Package'
          //-   && item.image_type_id != 3
          //-   && item.image_type.trim()!='Detail'
          //-   && item.image_type_id != 7
          //-   && item.image_type.trim()!='Proximal Connector'
          //-   && item.image_type_id != 8
          //-   && item.image_type.trim()!='Distal Connector'
          //-   && item.image_type_id != 9
          //-   && item.image_type.trim()!='Full Product - PS'
          //-   && item.image_type_id != 2
          //-   && item.image_type.trim()!='Full Product'
          //-   && item.image_type_id != 1
          //-   && item.image_type.trim()!='OEM')})];
          //- this.product.images = imgs;
          let imgs = this.product.images.filter((item) => {
            return !item.image_type_priority && item.image_type_priority != 0;
          });
          let imgsWp = this.product.images.filter((item) => {
            return item.image_type_priority || item.image_type_priority == 0;
          });
          imgsWp.sort((a, b) => {
            if (a.image_type_priority < b.image_type_priority) {
              return -1;
            } else if (a.image_type_priority > b.image_type_priority) {
              return 1;
            }
            return 0;
          });
          this.product.images = [...imgsWp, ...imgs];
          //- console.log('imgs', this.product.images);
          this.product.images.forEach((img) => {
            if (img.image_type == 'Full Product') {
              this.image_url = img.image_link;
            }
          });
          if (this.image_url == '') {
            this.image_url = this.product.images[0].image_link;
          }
        } else if (this.image_url == '') {
          this.image_url =
            this.product && this.product.family
              ? this.product.family.image_link_connector_distal
              : '';
        }
        if (this.image_url) this.showImage(this.image_url);
        this.setImagePreviewHeight();
      }
    },
    setImagePreviewHeight() {
      setTimeout(() => {
        const elmnt = document.getElementById('image-preview');
        if (elmnt) elmnt.style.height = elmnt.offsetWidth + 'px';

        const element = document.getElementsByClassName('mob-carousel-item');
        element.forEach(function (item) {
          if (item) item.style.height = item.offsetWidth - 5 + 'px';
          //- console.log(item.offsetWidth);
        });
      }, 200);
    },
    async loadProductCertificates() {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: `/products/${this.product_id}/certificates` },
        false
      );
      if (r.success) {
        //- console.log(r.data.product_certificates);
        this.product_certificates = r.data.product_certificates;
      }
    },
    async loadProductDocuments() {
      let api = useApiUtilStore();
      let r = await api.callApi(
        { method: 'GET', url: `/products/${this.product_id}/documents` },
        false
      );
      if (r.success) {
        //- console.log(r.data.product_certificates);
        this.product_documents = r.data.product_documents.map((item) => {
          return {
            ...item,
            documentType: this.documentTypes.find(
              (docType) => item.document_type_id === docType.id
            ).name_en,
          };
        });
      }
    },

    loadCompatibility(procucts) {
      this.compatibility = [];
      let groupArray = this.groupBy(procucts.compatibility, 'brand_en');
      for (let groupKey in groupArray) {
        if (groupArray[groupKey] && groupArray[groupKey].length) {
          this.compatibility.push({
            brand_en: groupKey,
            brand_zh: groupArray[groupKey][0].brand_zh,
            model: this.getModel(groupArray[groupKey]),
          });
        }
      }
      //- console.log('compatibility', this.compatibility);
    },
    loadOemReferences(products) {
      this.oem_references = [];

      let groupArray = this.groupBy(products.oem_references, 'brand_en');
      for (let groupKey in groupArray) {
        if (groupArray[groupKey] && groupArray[groupKey].length) {
          let publishedItems = groupArray[groupKey].filter(
            (item) => item.publish
          );
          if (publishedItems.length > 0) {
            this.oem_references.push({
              brand_en: groupKey,
              brand_zh: groupArray[groupKey][0].brand_zh,
              name: this.getOemName(publishedItems),
            });
          }
        }
      }
      if (
        !this.oem_references.find(
          (oemr) => oemr.name == this.product.sku || oemr.brand_en == 'Orantech'
        )
      ) {
        this.oem_references.splice(0, null, {
          brand_en: 'Orantech',
          name: this.product.sku,
        });
      }

      console.log('oem references', this.oem_references);
      //- console.log('loadOemReferences', this.oem_references);
    },
    /**
     * Removes duplicate OEM part number (SKU) references if the customer or buyer-specific sku is available to replace the Orantech one.
     * The Orantech SKU will be added to the oem references collection (if needed), so a reference to it remains in the appropriate place.
     * Note, this method should be invoked AFTER oem references, product customer, and product buyer data has been retrieved.
     */
    adjustOemReferencesForExtendedSkus() {
      // Remove customer sku reference if exists
      let custPartNumIdx = this.oem_references.findIndex(
        (oemr) => oemr.name == this.product.customer_sku
      );
      if (custPartNumIdx >= 0) {
        this.oem_references.splice(custPartNumIdx, 1);
      }
      // Remove buyer sku reference if exists
      let buyerPartNumIdx = this.oem_references.findIndex(
        (oemr) => oemr.name == this.buyer_product_info.sku
      );
      if (buyerPartNumIdx >= 0) {
        this.oem_references.splice(buyerPartNumIdx, 1);
      }
      // Add Orantech part number reference if it is not in list.
      if (
        !this.oem_references.find(
          (oemr) => oemr.name == this.product.sku || oemr.brand_en == 'Orantech'
        )
      ) {
        this.oem_references.splice(0, null, {
          brand_en: 'Orantech',
          name: this.product.sku,
        });
      }
      this.oem_references.sort((a, b) => {
        return a.brand_en.localeCompare(b.brand_en);
      });
    },

    async loadBuyerPrice() {
      if (this.isLoggedIn && this.is_buyer && this.org_principal) {
        let api = useApiUtilStore();
        let r = await api.callApi({
          method: 'POST',
          url: `/buyer/org/${this.org_principal.id}/prices_v2`,
          body: {
            product_ids: [this.product_id],
          },
          jwt: VueOfflineStorage.get('jwt'),
        });
        if (r.success && r.data.length > 0) {
          this.buyer_price_info = r.data[0];
          // blank out buyer price when prices are the same as list, or there is no buyer price
          console.log('@@@@@@@@@@', this.buyer_price_info, this.product);
        }
      }
    },
    async loadBuyerProduct() {
      if (this.isLoggedIn && this.is_buyer && this.org_principal) {
        let api = useApiUtilStore();
        let r = await api.callApi({
          method: 'GET',
          url: `/buyer/org/${this.org_principal.id}/product/${this.product_id}`,
          jwt: VueOfflineStorage.get('jwt'),
        });
        if (r.success && r.data !== {}) {
          this.buyer_product_info = r.data;
          //push sku into oem references
        }
      }
    },

    getModel(items) {
      if (items && items.length) {
        let spec = [...items.map((c) => c.model)];
        return spec.join(', ');
      }
      return '';
    },

    getOemName(items) {
      if (items && items.length) {
        let spec = [...items.map((c) => c.name)];
        return spec.join(', ');
      }
      return '';
    },

    //- groupByKey(list, key, {omitKey=false}){
    //-   return list.reduce((hash, {[key]:value, ...rest}) => ({...hash, [value]:( hash[value] || [] ).concat(omitKey ? {...rest} : {[key]:value, ...rest})} ), {})
    //- },

    groupBy(array, key) {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    },
    getLifeCycleById(id) {
      return this.lifecycles.find((item) => item.id === id);
    },
    loadSpecification(procucts) {
      this.specifications = [];
      //- this.specifications.push({
      //-   name: 'Filter options',
      //-   value: this.getFilterOption(),
      //- });
      if (procucts.filter_options && procucts.filter_options.length > 0) {
        procucts.filter_options.forEach((item) => {
          // Fallback to en if zh does not eixt
          const toUseLocale = item['filter_' + this.$i18n.locale]
            ? this.$i18n.locale
            : 'en';
          this.specifications.push({
            name: item['filter_' + toUseLocale],
            value: item['option_' + toUseLocale],
          });
        });
      }
      if (procucts.lifecycle_id) {
        const productLifeCycle = this.getLifeCycleById(procucts.lifecycle_id);
        this.specifications.push({
          name: this.$t('product.label.lifecycle'),
          value: productLifeCycle['name_' + this.$i18n.locale],
        });
      }
      if (procucts.weight_kg) {
        this.specifications.push({
          name: 'Weight',
          value: procucts.weight_kg ? procucts.weight_kg + 'g' : '',
        });
      }

      if (this.getUnit(procucts.packaging_factor) != '') {
        this.specifications.push({
          name: 'Unit',
          value: this.getUnit(procucts.packaging_factor),
        });
      }

      if (procucts.warranty_duration_months) {
        this.specifications.push({
          name: 'Warranty',
          value: procucts.warranty_duration_months
            ? procucts.warranty_duration_months + ' months'
            : '',
        });
      }

      //- if(procucts.lifecycle_en){
      //-   this.specifications.push({
      //-     name: 'Lifecycle',
      //-     value: procucts.lifecycle_en ? procucts.lifecycle_en: "",
      //-   });
      //- }

      if (this.getCertificates != '') {
        this.specifications.push({
          name: 'Certificates',
          value: this.getCertificates,
        });
      }

      if (procucts.note_client) {
        this.specifications.push({
          name: 'Notes',
          value: procucts.note_client ? procucts.note_client : '',
        });
      }

      //- console.log(this.specifications);
    },
    //     async loadProductInSametFamily() {
    //   let api = useApiUtilStore();
    //   let procuctConnect = [this.product.family_id, ...this.product.family_connections];
    //   if (!this.product.family_id && (!this.product.family_connections || this.product.family_connections == 0)) return false;
    //   let parms = { with: 'images' };
    //   parms.family_ids = procuctConnect.length === 0 ? undefined : procuctConnect.join('|');
    //   let r = await api.callApi({ method: 'GET', url: `/products/quicksearch`, parms, jwt: VueOfflineStorage.get('mtcustomer') }, false);
    //   let product_images = null;
    //   if (r.success) {
    //     //- console.log('quicksearch?family_id', r.data);
    //     let family_products = r.data.products;
    //     let index = family_products.findIndex(prod => (prod.id == this.product.id));
    //     if (index != -1) {
    //       family_products.splice(index, 1);
    //     }
    //     product_images = r.data.product_images;
    //     if (product_images && product_images.length > 0) {
    //       family_products.forEach(product => {
    //         let item = product_images.find(p => p.product_id == product.id && p.image_type == "Full Product");
    //         if (item) {
    //           product['image_url'] = item.image_link;
    //         } else {
    //           item = product_images.find(p => p.product_id == product.id && p.image_type == "Full Product - PS");
    //           if (item) {
    //             product['image_url'] = item.image_link;
    //           } else {
    //             item = product_images.find(p => p.product_id == product.id);
    //             if (item) {
    //               product['image_url'] = item.image_link;
    //             }
    //           }
    //         }
    //       });
    //     }
    //     // 1. Alternative Options: Products in the same family, same category, same product Type and same Lifecycle.
    //     // 2. Use With: Products in the same family but not in the same category, product type or Lifecycle.
    //     // 3. Customers Also Purchased: Product in the related family.
    //     this.similar_products = family_products.filter(item =>
    //       (item.family_id == this.product.family_id) &&
    //       (item.category_id == this.product.category_id) &&
    //       (item.lifecycle_id === this.product.lifecycle_id) &&
    //       (item.product_type_id === this.product.product_type_id)
    //     );
    //     this.customer_views = family_products.filter(item =>
    //       (item.family_id == this.product.family_id) &&
    //       ((item.category_id != this.product.category_id) ||
    //         (item.product_type_id !== this.product.product_type_id) ||
    //         (item.lifecycle_id !== this.product.lifecycle_id))
    //     );
    //     this.use_with = family_products.filter(item => (item.family_id != this.product.family_id));
    //   }
    // },

    async loadProductInSametFamily() {
      let api = useApiUtilStore();
      let procuctConnect = [
        this.product.family_id,
        ...this.product.family_connections,
      ];
      if (
        !this.product.family_id &&
        (!this.product.family_connections ||
          this.product.family_connections == 0)
      )
        return false;
      let parms = { with: 'images' };
      parms.family_ids =
        procuctConnect.length === 0 ? undefined : procuctConnect.join('|');
      let r = await api.callApi(
        {
          method: 'GET',
          url: `/products/quicksearch`,
          parms,
          jwt: VueOfflineStorage.get('mtcustomer'),
        },
        false
      );
      let product_images = null;
      if (r.success) {
        let family_products = r.data.products;
        let index = family_products.findIndex(
          (prod) => prod.id == this.product.id
        );
        if (index != -1) {
          family_products.splice(index, 1);
        }
        product_images = r.data.product_images;
        if (product_images && product_images.length > 0) {
          family_products.forEach((product) => {
            let item = product_images.find(
              (p) =>
                p.product_id == product.id && p.image_type == 'Full Product'
            );
            if (item) {
              product['image_url'] = item.image_link;
            } else {
              item = product_images.find(
                (p) =>
                  p.product_id == product.id &&
                  p.image_type == 'Full Product - PS'
              );
              if (item) {
                product['image_url'] = item.image_link;
              } else {
                item = product_images.find((p) => p.product_id == product.id);
                if (item) {
                  product['image_url'] = item.image_link;
                }
              }
            }
          });
        }
        // Filter out duplicates in similar_products
        let similarProductIds = new Set();
        this.similar_products = family_products.filter((item) => {
          if (similarProductIds.has(item.id)) {
            return false; // If already added, skip
          } else {
            similarProductIds.add(item.id); // Add to set to avoid duplicates
            return true;
          }
        });

        // Filter customer views and use with products
        this.customer_views = family_products.filter(
          (item) =>
            item.family_id == this.product.family_id &&
            (item.category_id != this.product.category_id ||
              item.product_type_id !== this.product.product_type_id ||
              item.lifecycle_id !== this.product.lifecycle_id)
        );
        this.use_with = family_products.filter(
          (item) => item.family_id != this.product.family_id
        );
      }
    },

    showImage(img) {
      //- const VALID_IMG = /http[s]?:\/\/[\w-\.\/]*\.(gif|jpeg|jpg|png)/;
      //- if (VALID_IMG.test(img)) this.preview_image = img;
      this.preview_image = this.getUrl(img);
    },

    showVideo(video) {
      this.preview_video = video;
    },

    closeVideoPreview() {
      this.preview_video = null;
    },

    // cart-related listeners
    itemChanged() {
      let item = this.findCartItem(this.product.id);
      if (item) {
        if (this.my_quantity == 0) {
          this.removeCartItem(this.product.id);
        } else {
          this.updateCartItem(this.product.id, this.my_quantity);
          //- this.msg="Item successfully updated to your cart.";
          //- this.showAlert();
        }
      } else {
        const yourPrice = this.yourPrice;
        this.createCartItem({
          id: this.product.id,
          name_en: this.product.name_en,
          name_zh: this.product.name_zh,
          image_url: this.image_url,
          description_en: this.product.description_en,
          description_zh: this.product.description_zh,
          oem: this.product.oem,
          sku: this.product.customer_sku || this.product.sku,
          price: this.isParts(this.product.category_id)
            ? 'Request quote'
            : this.showYourPrice ? yourPrice : this.translatePrice(this.product, 'list_price'),
          quantity: this.my_quantity,
        });
      }
      this.my_quantity = 1;
      this.msg = this.$t('global.label.Item_successfully');
      this.showAlert();
    },
  },
};
</script>
<style lang="scss">
.breadcrumb-block .breadcrumb-trail .list-item-0 {
  display: none;
}
.product-doc-container {
  margin-left: 10px;
  padding-top: 10px;
}
.document-link {
  color: black !important;
  font-size: 15px;
  opacity: 70%;
}
.document-link:hover {
  color: #38bcd9 !important;
  opacity: 90%;
  opacity: 100%;
}
.doc-main {
  gap: 10px;
}
#product {
  .similar-products {
    .slick-slider {
      .slick-prev {
        left: -7px !important;
        z-index: 9;

        &:before {
          color: gray !important;
        }
      }

      .slick-next {
        right: -10px !important;
        z-index: 9;

        &:before {
          color: gray !important;
        }
      }
    }
  }

  .thumbnails {
    .slick-slider {
      .slick-prev {
        left: -20px !important;

        &:before {
          color: gray !important;
        }
      }

      .slick-next {
        right: -20px !important;

        &:before {
          color: gray !important;
        }
      }

      .slick-list {
        .slick-track {
          width: 100vw !important;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.textAlignLeft {
  text-align: left !important;
  margin-left: 11px !important;
}
.doc-link {
  color: black;
}
#product {
  .show-more {
    padding-left: 1rem;

    a {
      text-decoration: underline;
      color: #7f7f7f;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .show-more:not(.expanded) {
    position: absolute;
    bottom: -15px;

    @media (max-width: 767px) {
      bottom: 10px;
    }
  }

  .breadcrumb-block {
    background-color: #f2f3f4;

    .breadcrumb-trail {
      span {
        color: #4a4a4a;
        font-size: 14px;

        a {
          color: #4a4a4a;

          &:hover {
            text-decoration: none;
          }
        }

        &.list-item-last {
          pointer-events: none;
        }
      }

      .active {
        color: black;
        font-weight: 600;
      }
    }
  }

  .top-row-wrap {
    .left-content {
      .left-inner-content {
        margin: 0.5rem;
        padding: 15px;
        box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
        border-radius: 5px;
        margin-bottom: 0px;
        min-height: 320px;

        @media (max-width: 767px) {
          min-height: auto;
        }

        .option-heading {
          color: #272727;
          size: 14px;
          font-weight: 500;
        }

        a {
          text-decoration: none;
          color: black;
        }
      }

      .similar-products {
        margin-top: 15px;

        .option-header {
          padding: 6px 10px;
          border: 1px solid #ededed;
          border-radius: 5px;
          box-shadow: 0rem 0rem 0.2rem rgb(0 0 0 / 18%) !important;
          margin-bottom: 10px;
          color: #272727;
          size: 14px;
          font-weight: 500;
        }

        span {
          paddin: 5px;

          img {
            box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
            width: 100px;
            height: 100px;
            cursor: pointer;
          }

          .sp-title {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    .right-content {
      border-left: 1px solid #dee2e6 !important;
      border-bottom: 1px solid #dee2e6 !important;

      .right-inner-content {
        margin: 0.5rem;

        .image-preview-wrap {
          .image-preview {
            height: auto;
            min-height: 250px;
            width: 100%;
            padding: 5px;
            box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
            border-radius: 5px;

            video,img {
            
              width: 90%;
              height: 90%;
            }
            video{
              margin-left:10px;
            }
          }

          .thumbnails {
            margin-top: 1rem;

            span {
              padding: 5px;

              video, img {
                box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;
                width: 80px;
                height: 80px;
                cursor: pointer;
              }
            }
          }
        }

        .product-details {
          .product-name {
            color: #272727;
            font-size: 24px;
            font-weight: 600;
          }

          .product-desc {
            color: #525252;
            font-size: 16px;
          }

          .product-note {
            color: #e92f2d;
            font-size: 12px;
            font-weight: 600;
          }

          .mob-product-img-carousel {
            display: none;
          }

          .procuct-spec {
            margin-top: 2rem;

            .strikethrough {
              text-decoration: line-through;
            }

            .spec-item {
              margin-bottom: 0.5rem;

              .item-label-buying-option {
                display: contents;
                font-size: 14px;
                color: #525252;
                font-weight: 600;
              }

              .item-label {
                font-size: 14px;
                color: #525252;
                font-weight: 600;

                .bullet {
                  margin-right: 0.5rem;
                  padding-bottom: 2px;
                  width: 9px;
                }
              }

              /* for price */
              .price-group {
                display: inherit;
                align-items: center;

                .price-value {
                  margin-left: auto;
                  color: #38bcd9;
                  font-size: 14px;
                  font-weight: 600;

                  span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #525252;
                  }

                  .request-list-price {
                    color: #8d8601;
                    font-weight: bold;
                  }
                }
              }

              .price-desc {
                margin-left: 1.1rem;
                color: #14a757;
                font-size: 14px;
                font-weight: 400;
              }

              /* for price */
              .quantity-group {
                display: flex;
                align-items: center;

                .quantity-input {
                  margin-left: auto;
                  width: 70px;
                  display: inline;
                  font-size: 14px;
                }
              }

              .quantity-stock {
                margin-left: 1.1rem;
                font-size: 0.84rem;
                font-weight: 500;
                color: #14a757;
              }

              .out-of-stock {
                font-weight: 600;
                font-size: 14px;
                color: #7f7f7f;
                margin-top: 2px;
              }

              .out-of-stock-sub-title {
                font-weight: 600;
                font-size: 12px;
                color: #7f7f7f;
              }

              /* for Unit */
              .unit-group {
                display: flex;
                align-items: center;

                .unit-value {
                  margin-left: auto;
                }
              }

              /* for SKU */
              .sku-group {
                display: flex;
                align-items: center;

                .sku {
                  margin-left: auto;
                }
              }
            }

            .add-to-cart-btn {
              padding: 0.8rem;
              font-size: 14px;
              font-weight: 600;

              &:hover {
                color: white !important;
              }
            }
          }
        }
      }
    }
  }

  .product-specifications-details-wrap {
    margin: 2rem 0;
    padding: 0rem 3.2rem;

    @media (max-width: 767px) {
      margin: 1rem 0;
      padding: 0rem 1.8rem;
    }

    .product-specifications-details {
      padding: 2rem 0.5rem;
      border-radius: 5px;
      box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;

      @media (max-width: 767px) {
        padding: 1.5rem 0.5rem;
      }

      .search-reference,
      .search-compatiblity {
        .input-match {
          position: absolute;
          right: 35px;
          top: 50px;
          font-size: 12px;
          color: #14a757;

          @media (max-width: 767px) {
            right: 20px;
            top: 83px;
          }
        }
      }

      .match-divider {
        font-size: 12px;
        color: #38bcd9;
        padding-top: 3px;
      }

      .product-specifications {
        border-right: 1px solid #a0a0a0;

        @media (max-width: 767px) {
          padding-bottom: 2rem;
          border-bottom: 1px solid #a0a0a0;
          border-right: none;
          padding-left: 0;
          padding-right: 0;
        }

        .header-title {
          font-size: 16px;
          font-weight: 600;
          margin-left: 0.5rem;
          margin-bottom: 1rem;
        }

        .group-item {
          padding-bottom: 1rem;
          padding-left: 0.7rem;

          img {
            padding-right: 0.4rem;
          }

          @media (max-width: 767px) {
            font-size: 0.85rem;
          }
        }
      }

      .product-reference {
        border-right: 1px solid #a0a0a0;

        @media (max-width: 767px) {
          padding-bottom: 2rem;
          padding-top: 2rem;
          border-bottom: 1px solid #a0a0a0;
          border-right: none;
          padding-left: 0;
          padding-right: 0;
        }

        .header-title {
          font-size: 16px;
          font-weight: 600;
          margin-left: 0.5rem;
          margin-bottom: 1rem;
        }

        .search-reference {
          padding: 0 0.5rem;

          .input-item {
            font-size: 14px;
            height: auto;
            padding: 0.4rem 0.4rem 0.5rem 2.2rem;
            color: #7f7f7f;
          }

          img {
            position: relative;
            top: -33px;
            left: 12px;
          }
        }

        .group-item {
          padding-bottom: 1rem;
          padding-left: 0.7rem;

          img {
            padding-right: 0.4rem;
          }

          @media (max-width: 767px) {
            font-size: 0.85rem;
          }
        }
      }

      .product-compatiblity {
        @media (max-width: 767px) {
          padding-top: 2rem;
          padding-left: 0;
          padding-right: 0;
        }

        .header-title {
          font-size: 16px;
          font-weight: 600;
          margin-left: 0.5rem;
          margin-bottom: 1rem;
        }

        .search-compatiblity {
          padding: 0 0.5rem;

          .input-item {
            font-size: 14px;
            height: auto;
            padding: 0.4rem 0.4rem 0.5rem 2.2rem;
            color: #7f7f7f;
          }

          img {
            position: relative;
            top: -33px;
            left: 12px;
          }
        }

        .group-item {
          padding-bottom: 1rem;
          padding-left: 0.7rem;

          img {
            padding-right: 0.4rem;
          }

          @media (max-width: 767px) {
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .product-features-block {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 767px) {
    .breadcrumb-block {
      padding-left: 0px;
      background-color: #f2f3f4;

      .breadcrumb-trail {
        .list-item {
          &-first {
            display: block;
          }

          &-0 {
            display: block;
            padding-left: 5px;
            line-height: 1.7;

            img {
              transform: rotate(90deg);
            }
          }

          &-1 {
            display: block;
            padding-left: 15px;
            line-height: 1.7;

            img {
              transform: rotate(90deg);
            }
          }

          &-2 {
            display: block;
            padding-left: 25px;
            line-height: 1.7;

            img {
              transform: rotate(90deg);
            }
          }

          &-last {
            display: block;
            padding-left: 0px;
            line-height: 1.7;
            cursor: pointer;
            pointer-events: auto !important;

            img {
              transform: rotate(180deg);
            }

            a {
              color: #7f7f7f;

              &:hover {
                text-decoration: none;
                color: #4a4a4a;
              }
            }
          }
        }

        .mobile-b-hide {
          display: none;
        }

        .mobile-b-show {
          padding-left: 0px;
          display: block;
        }
      }
    }

    .top-row-wrap {
      flex-direction: column-reverse;

      .left-content {
        padding: 0px;
        margin-top: 1rem;

        .left-inner-content {
          margin-top: 1rem;
          margin: 0;
        }
      }

      .right-content {
        border-left: none !important;
        border-bottom: none !important;
        padding: 0px;

        .right-inner-content {
          .image-preview-wrap {
            display: none;
          }

          .product-details {
            padding: 0px;

            .product-name {
              font-size: 14px;
              font-weight: 700;
            }

            .product-desc {
              font-size: 12px;
            }

            .mob-product-img-carousel {
              display: block;
              margin-top: 1.5rem;
              margin-bottom: 3.5rem !important;

              .mob-carousel-item {
                box-shadow: 0 0 5px #dddddd;
                border-radius: 10px;

                img {
                  border-radius: 10px;
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .procuct-spec {
              margin-top: 2rem;
              border-radius: 5px;
              padding: 10px 0px;
              margin-left: -6px;
              margin-right: -6px;
              box-shadow: 0rem 0rem 0.5rem rgb(0 0 0 / 18%) !important;

              .spec-item {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                margin-bottom: 0.5rem;

                .item-label {
                  font-size: 10px;
                  color: #525252;
                  font-weight: 600;

                  .bullet {
                    margin-right: 0.5rem;
                    padding-bottom: 2px;
                    width: 9px;
                  }
                }

                /* for price */
                .price-group {
                  .price-value {
                    font-size: 12px;
                    font-weight: 600;

                    span {
                      font-size: 12px;
                      font-weight: 400;
                    }

                    .request-list-price {
                      color: #8d8601;
                      font-weight: bold;
                    }
                  }
                }

                .price-desc {
                  font-size: 10px;
                }

                /* for price */
                .quantity-group {
                  .quantity-input {
                    width: 60px;
                    font-size: 12px;
                  }
                }

                .quantity-stock {
                  font-size: 10px;
                }

                .out-of-stock {
                  font-size: 12px;
                  text-align: right !important;
                }

                .out-of-stock-sub-title {
                  font-size: 9px;
                  text-align: right !important;
                }

                /* for Unit */
                .unit-group {
                  .unit-value {
                    font-size: 12px;
                  }
                }

                /* for SKU */
                .sku-group {
                  .sku {
                    font-size: 12px;
                  }
                }
              }

              .add-to-cart-btn {
                padding: 0.8rem;
                font-size: 14px;
                font-weight: 600;

                &:hover {
                  color: white !important;
                }
              }
            }
          }
        }
      }
    }

    .product-features-block {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

@media (min-width: 768px) {
  .mobile-b-show {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mid-screen-hide {
    display: none;
  }
}
.product-video{
  height:80px;
  width:80px;
}
.preview-product-video{
 height:auto;
  width:100%;
}
.thumbnail-video {
  cursor: pointer; /* Indicates the element is clickable */
  width: 100%; /* Ensure it takes the full width of the container */
  height: auto; /* Maintain aspect ratio */
  display: block;
  pointer-events:none; /* Ensure it behaves as a block element */
}
</style>
