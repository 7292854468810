<template lang="pug">
#pumps
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Infusion Pumps 
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_compatible_accessories_for_GE_Phillips_Masimo_Nellcor_Mindray_Nihon-Koden_Datex.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p On our brand new replacement parts appearance and functionality are equivalent to OEMs. With us you enjoy up to 50% savings compared to other suppliers and we have most items in stock and ready to ship. We carry replacement parts for the following brands of Infusion Pumps: BD/Carefusion/Alaris, Baxter, Medfusion and Hospira.
        .item-wrap(v-for="(sp, i) in pumpData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    components: {
        AboutProductItem,
        MissionPromiseStatement,
        Footer
    },
    data: () => ({
        pumpData: [
            {
              title: "Becton Dickinson/Carefusion/Alaris",
              models: ["Alaris 8000", "Alaris 8015", "Alaris 8100", "Alaris 8120"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/infusion-parts-BD.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_005_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_065_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_048.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_055_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-KLN_005_08.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_005_06.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_005_09.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_005_07.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_070_03.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_034_06.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_034_10.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_070_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_070_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_070_05.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_034_13.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_034_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-BD-KLN_034_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-XH216D.jpg"
              ],
            },
            {
              title: "Baxter",
              models: ["InfusO.R.", "Sigma Spectrum"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/infusion-parts-Baxter.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Baxter-KLN_075_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Baxter-KLN_049_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Baxter-KLN_049_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Baxter-KLN_049_03.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Baxter-KLN_057.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Baxter-KLN_032_06.jpg"
              ],
            },
            {
              title: "Smiths/Medfusion",
              models: ["Medfusion 3500", "Medfusion 4000"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/infusion-parts-medfusion.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Infusion-Parts-Medfusion-KLN_53.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Medfusion-KLN_053_05A.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-Medfusion-KLN_053_05B.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Infusion-Parts-Medfusion-KLN_053_03.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Infusion-Parts-Medfusion-KLN_053_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Infusion-Parts-Medfusion-KLN_011.jpg"
              ],
            },
            {
              title: "ICU/Hospira/Abbott",
              models: ["Plum A+", "Plum 360"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/infusion-parts-ICU.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_01A.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_02.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_05.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_04A.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_03.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_08.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_06.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_054_07A.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Infusion-ICU-KLN_076_01.jpg"
              ],
            }
          ],
    })
  }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>