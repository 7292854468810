<template lang="pug">
#parts
  b-container.content-wrap.light-font
    b-row
      b-col.link-img-wrap(cols="6" md="3" lg="3" v-for="(part, key) in parts" :key="key")
        //- b-link(href="#" :to="{path: part.url}") (href="#" :to="{path: part.url}")
        b-link(href="#" :to="{path: part.url}")
        
          b-img.link-img.mt-blue(:src="part.image_url")
        b-link.link-img-title.d-block(href="#" :to="{path: part.url}")
         TranslateValue(:sourceObj="part") 
    h1.accessories-title.light-font.mt-blue.mt-4 {{ $t('parts.parts_heading')}}
    p {{ $t('parts.parts_para') }}
    .quality-block
            .quality-title.mt-blue
                
            p {{$t('general.quality_heading')}}
    p {{ $t('parts.l1') }}    
    ul.quality-list
    
        li {{ $t('parts.l2') }}
        li {{ $t('parts.l3') }}
            //- li Orantech's unique QR code labeling system now allows our customers to track every cable from manufacturing to consumer and beyond. Access to a complete product history, specifications, sales and support is now at your fingertips.
        li {{ $t('parts.l4') }}
        li {{ $t('parts.l5') }}
        //- a(href="/downloads") Download page  
        //- | for catalogs and brochures. Our parts are compatible with all major brands of the following 4 equipment categories:
    //- p 1. Fetal Transducers: Philips/Avalon, GE/Corometrics, Huntleigh-Sonicaid, and Edan
    //- p 2. Telemetry equipment: Philips, GE and Mindray
    //- p 3. Infusion Pumps: BD/Carefusion/Alaris, Baxter, Medfusion and Hospira
    //- p 4. Monitor/Modules: Philips and GE
    
    //- h2.parts-title.light-font.mt-blue.vertical-space-above Review - Repair Parts
    //- p See what biomeds are saying about our replacement parts. Here a biomed technician on their YouTube channel
    //- a(href="https://www.youtube.com/c/BetterBiomed" target="_blank") Better Biomed
    //- |  reviews our top case replacement part for the popular Medfusion 3500 Infusion Pump.

    //- blockquote
    //-   .quote
    //-     | "Let me assure you guys that this guy [the compatible part] is an equivalent product [to the OEM]... It's got all the rubber seals and everything already installed...
    //-     | I've checked the studs that are pre-mounted, they're excellent. I've checked the centering of the glass, it looks excellent. The fasteners, everything is exactly how I would expect...
    //-     | It actually looks like these ones [the studs on the compatible part] have more material to create that interface between the brass and the plastic so it actually might be an improvement [over the OEM] even though it looks like each of these studs has less plastic on it... 
    //-     | It could be an improvement because having more brass gives them more material to adhere to...
    //-     | The control panel on this aftermarket part feels fantastic, each of these buttons is real solid...
    //-     b.bolder It's an excellent product.
    //-     | I've checked everything with the caliper just to make sure that there is continuity between these two parts...
    //-     | That just goes to show... they can create a part that's as good if not better than the OEM...
    //-     | If the OEM was more reasonable with some of their repair part prices then that wouldn't exist, would it? But here we are."
    //-   .reference
    //-     b.bolder Justin from Better Biomed
    //- .video
    //-     iframe(src="https://www.youtube.com/embed/Esgs4L5few0?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
    //- br
  
  MissionPromiseStatement 
  Footer 
</template>

<script>

import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
import TranslateValue from '../../components/TranslateValue.vue';
export default {
  name: 'Parts',
  components: {
    Footer,
    MissionPromiseStatement,
    TranslateValue
  },
  data(){
        return {
            parts:[
                {
                    id: 1,
                    name_en: 'Infusion Pumps',
                    name_zh:"输液泵",
                    image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Infusion_Pumps_medical_equipment.jpg",
                    url:'/parts/pumps'
                },
                {
                    id: 2,
                    name_en: 'Telemetry',
                    name_zh:"遥测",
                    image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Telemetry_medical_equipment.jpg",
                    url:'/parts/telemetry'
                },
                {
                    id: 3,
                    name_en: 'Monitor/Module',
                    name_zh:"监护仪/模块",
                    image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Montior_Modules_medical_equipment.jpg",
                    url:'/parts/monitor'
                },
                {
                    id: 4,
                    name_en: 'Fetal Transducers',
                    name_zh:"胎监探头",
                    image_url: "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Fetal_medical_equipment.jpg",
                    url:'/parts/transducers'
                },
            ]
        };
  },
}
</script>

<style lang="scss" scoped>
  .link-img-wrap {
    text-align: center;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }
  .link-img {
    margin: 20px 0 0;
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
  }
  .link-img-block {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .link-img-title {
    text-decoration: none;
    color: #68C3C5;
    font-weight: 600;
    background-color: transparent;
  }
  .video {
    text-align: center;
    iframe{
        width: 100%;
        height: 580px;
        @media(max-width: 767px){
            width: 100%;
            height: 300px;
        }
        @media(max-width: 543px){
            width: 100%;
            min-height: 80vw;
        }
    }
  }
  .quote {
    padding-right: 50px;
    padding-left: 50px;
    font-style: oblique;
  }
  .reference {
    text-align: right;
    padding-right: 95px;
    padding-bottom: 10px;
  }
  .bolder {
    font-weight:700;
  }
  .vertical-space-above {
    padding-top: 20px;
  }
</style>