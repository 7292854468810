<template lang="pug">
b-form.p-2.form-container( @submit="onSubmit" autocomplete="off" )
  p.error(v-if="!token") Invalid request.

  h4 {{ $t('auth.reset_password_instructions') }}
  
  b-form-group( id="input-group-2" :label="$t('auth.password')" label-for="input-2" )
    b-form-input( id="input-2" v-model="password" required type="password" :placeholder="$t('auth.enter_password')" )

  b-form-group( id="input-group-3" :label="$t('auth.confirm_password')" label-for="input-3")
    b-form-input( id="input-3" v-model="confirm_password" required type="password" :placeholder="$t('auth.enter_confirm_password')")

  b-button( type="submit" :variant="is_valid ? 'primary' : 'secondary'" :disabled="!is_valid") {{ $t("auth.reset_password") }}

  .mt-2
    b-link( href="/login") {{ $t("auth.go_back_to_login") }}
</template>

<script>
import { mapActions } from 'pinia';
import { useAuthStore } from '@/stores/auth-st';

export default {
  name: 'ResetPassword',

  props: {
    token: {type: String, required: true}
  },
  data(){
    return {
      password: "",
      confirm_password: "",
    };
  },
  computed:{
    is_complex(){
      if(!this.password) return false;
      return this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/);
    },
    is_valid(){
      return this.password &&
      this.is_complex &&
      this.confirm_password &&
      this.password===this.confirm_password;
    }
  },

  methods: {
    ...mapActions(useAuthStore, ['resetPassword']),

    async onSubmit(evt) {
      evt.preventDefault();
      
      try {
        if (this.password != this.confirm_password) {
          this.error = "Password must match";
          return;
        }

        if (this.token == "") {
          this.error = "Token not present";
          return;
        }

        let res = await this.resetPassword({token: this.token, password: this.password});
        
        if (res && res.complete)  this.$router.push({ name: "login" });

      } catch (err) {
        console.log(err);
        this.error = err.message;
        if (err && err.message && err.message == "Unauthorized") {
          this.error = this.$t("auth.login." + err.message);
        }
      }
    }
  }
}
</script>
<style scoped>
.form-container {
  display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 50px;
}
</style>
