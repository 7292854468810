<template lang="pug">
b-container(fluid) 
  b-card.mt-4.bg-light.border-0(v-if="!forgot")
    b-card-body
      b-card-text
        b-alert(variant="warning" v-if="!busy && has_error" show ) {{error}}
        b-alert(variant="info" dismissable fade v-if="!busy && has_message" show ) {{message}}
        b-form.mb-4
          input(
            type="hidden",
            name="redirect_uri",
            :value="redirect_uri || ''"
          )
          div.d-flex.login-form
            b-input-group(name="username")
              template.bb(#prepend='')
                b-input-group-text.bg-white.font-weight-bold Username:
              b-form-input.border-left-0(name="username" v-model="username" placeholder="Enter Username")

            b-input-group.ml-4("password", prepend="Password:")
              template.bb(#prepend='')
                b-input-group-text.bg-white.font-weight-bold Password:
              b-form-input.border-left-0(type="password" v-model="password"  placeholder="Enter Password")
        b-row
          b-col.d-flex.justify-content-center
            b-button.px-5.text-light(variant="primary", @click="processLogin") Login
        b-row
          b-col.d-flex.justify-content-center.pt-3
            b-link(@click="toggleForgot") Forgot password?
  b-card(v-if="forgot")
    b-card-body
      b-card-title 
        h4 {{ $t('auth.reset_password') }}
      b-card-text
        b-alert(variant="warning" :show="!busy && has_error" ) {{error}}
        b-alert(variant="info" dismissable fade :show="!busy && has_message" ) {{message}}
        b-form
          b-form-group(label="Email" )
            b-form-input(name="email" v-model="email")
          b-row
            b-col
              b-button(variant="primary" @click="processForgotPassword") {{ $t('auth.reset_password') }}
          b-row
            b-col.pt-3
              b-link.text-secondary(@click="toggleForgot") Login
</template>
  
<script>
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useAuthStore } from '@/stores/auth-st';
import { useBaseStore } from '@/stores/base-st';
import { VueOfflineStorage } from 'vue-offline';
export default {
  name: 'Login',

  computed: {
    ...mapState(useBaseStore, ['message', 'error', 'errors', 'busy', 'has_error', 'has_message']),
    ...mapState(useAuthStore, ['isLoggedIn','is_buyer']),
    ...mapWritableState(useAuthStore, ['username', 'password']),
  },

  data() {
    return {
      forgot: false,
      redirect_uri: '',
      email: '',
    };
  },

  methods: {
    ...mapActions(useAuthStore, ['login', 'logout', 'forgotPassword']),
    toggleForgot() {
      this.forgot = !this.forgot;
    },
    async processLogin() {
      
      await this.login();
      if(this.isLoggedIn){
         this.$router.push({ name: 'Landing' });
      }
    },
    processForgotPassword() {
      this.forgotPassword(this.email);
    }
  },

  created() {
    this.logout();

    // Stop validating prior sessions, if any.
    let iid = VueOfflineStorage.get('sid');
    if (iid) {
      clearInterval(iid);
      VueOfflineStorage.set('sid', null);
    }
  },

}
</script>
  