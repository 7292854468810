<template lang="pug">
#telemetry
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Telemetry
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Telemetry_medical_equipment.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p On our brand new replacement parts appearance and functionality are equivalent to OEMs. With us you enjoy up to 50% savings compared to other suppliers and we have most items in stock and ready to ship. We carry replacement parts for the following brands of Telemetry equipment: Philips, GE and Mindray.
        .item-wrap(v-for="(sp, i) in telemetryData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    components: {
        AboutProductItem,
        MissionPromiseStatement,
        Footer
    },
    data: () => ({
        telemetryData: [
            {
                title: "Philips",
                models: ["MX40", "M2601A", "M2601B/M4841A"],
                features: [
                    "Brand new replacements",
                    "Appearance and functionality are equivalent to OEMs",
                    "Quality is guaranteed",
                    "Up to 50% savings",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/telemetry-parts-philips.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-Philips-KLN_003_03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_003_09.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-Philips-KLN_003_04.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-Philips-KLN_003_05.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_015_01.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_015_03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_006_34.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_007_09.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_006_32.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_006_11.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-philips-KLN_006_33.jpg"
                ],
            },
            {
                title: "GE Healthcare",
                models: ["Aprex Pro", "Aprex Pro CH", "Aprex Pro FH", "Carescape T14"],
                features: [
                    "Brand new replacements",
                    "Appearance and functionality are equivalent to OEMs",
                    "Quality is guaranteed",
                    "Up to 50% savings",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/telemetry-parts-GE.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_043A.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_012B.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_043_05.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Tele-GE-M20_F081.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_010_08.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_044_03.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_010_11.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-GE-KLN_010_13.jpg"
                ]
            },
            {
                title: "Mindray/Datascope",
                models: ["Panorama Telepack 608"],
                features: [
                    "Brand new replacements",
                    "Appearance and functionality are equivalent to OEMs",
                    "Quality is guaranteed",
                    "Up to 50% savings",
                    "6 months warranty"
                ],
                imageUrls: [
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Telemetry-parts-Mindray.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_05.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_04.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_02.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_06.jpg",
                    "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_07.jpg"
                ]
            }
        ],
    })
  }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>