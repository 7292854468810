<template lang="pug">
#monitor
    b-container.content-wrap.light-font.pt-2
        .entity-head
            b-row.entity-head-content.mb-4
                b-col(cols="12") 
                    h1.mt-blue.light-font.mb-2 Monitor/Module
                b-col(cols="12" md="4")
                    b-img.link-img.w-100(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_new_replacement_parts_for_Montior_Modules_medical_equipment.jpg" alt="")
                b-col.mt-2(cols="12" md="8")
                    p On our brand new replacement parts appearance and functionality are equivalent to OEMs. With us you enjoy up to 50% savings compared to other suppliers and we have most items in stock and ready to ship. We carry replacement parts for the following brands of Monitor/Modules: Philips and GE.
        .item-wrap(v-for="(sp, i) in moduleData" :key="i")
            AboutProductItem(
            :title="sp.title"
            :dateInfo="sp.dateInfo"
            :brands="sp.brands"
            :models="sp.models"
            :features="sp.features"
            :imageUrls="sp.imageUrls"
            :popularProducts="sp.popularProducts"
            :downloadUrls="sp.downloadUrls")
    MissionPromiseStatement
    Footer
</template>

<script>
  import AboutProductItem from '@/components/AboutProductItem.vue'
  import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
  import Footer from '@/components/Footer.vue';
  export default {
    components: {
        AboutProductItem,
        MissionPromiseStatement,
        Footer
    },
    data: () => ({
        moduleData: [
            {
              title: "Philips",
              models: ["X2/MP2/M3002A", "M3000A", "M3001A", "M3012A", "M3014A", "M3015A", "M3016A", "MP5/20/30/40/50/60/70"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/monitor-parts-philips.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_040_06.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-M20_F082.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-monitor-Philips-KLN_009_03.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_019.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitot-Philips-KLN_040_05.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_040_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_021.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_029.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_013.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_042B.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_008_01.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLF_036_01F.jpg"
              ]
            },
            {
              title: "GE Healthcare",
              models: ["CAM14", "Dash", "Carescape B450", "Carescape B650"],
              features: [
                  "Brand new replacements",
                  "Appearance and functionality are equivalent to OEMs",
                  "Quality is guaranteed",
                  "Up to 50% savings",
                  "6 months warranty"
              ],
              imageUrls: [
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/monitor-Parts-GE.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-GE-KLN_014.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-GE-BPS14.jpg",
                  "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-GE-KLN_045.jpg"
              ],
            }
        ],
    })
  }
</script>

<style lang="scss" scoped>
.link-img {
    width: 100%;
    border: 3px solid #68C3C5;
    border-radius: 40px;
    border-top-left-radius: 0px !important;
}
</style>